import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./global";
// @ts-ignore
import reportWebVitals from "./reportWebVitals";
import styled, { keyframes } from "styled-components";

// 회전 애니메이션 정의
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// 페이드인 애니메이션 정의
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// 스타일이 적용된 로딩 컨테이너
const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
    animation: ${fadeIn} 0.5s ease-in-out;
`;

// 로고 이미지에 회전 애니메이션 적용
const RotatingLogo = styled.img`
    width: 80px;
    height: 80px;
    animation: ${rotate} 1.5s infinite linear;
    filter: drop-shadow(0 4px 8px rgba(15, 94, 156, 0.2));
    margin-bottom: 15px;
    border-radius: 50%;
    background-color: white;
    padding: 5px;
    box-shadow: 0 0 15px rgba(15, 94, 156, 0.3);
    object-fit: contain;
`;

// 로딩 텍스트 스타일
const LoadingText = styled.div`
    color: #0f5e9c;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    opacity: 0.8;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        sans-serif;
`;

// 앱 컴포넌트를 지연 로드하여 초기 로드 성능 개선
const App = React.lazy(() => import("./App"));

// 주요 이미지 프리로드 컴포넌트
const ImagePreloader = () => {
    useEffect(() => {
        // LCP 개선을 위한 중요 이미지 프리로드
        const imagesToPreload = [
            "/app_icon.webp",
            "https://myonbi.kr/static/media/home_top.c04fd6c755d100c56f20.webp",
        ];

        imagesToPreload.forEach((imageSrc) => {
            const link = document.createElement("link");
            link.rel = "preload";
            link.as = "image";
            link.href = imageSrc;
            link.type = imageSrc.endsWith(".webp") ? "image/webp" : "image/png";
            link.setAttribute("fetchpriority", "high");
            if (imageSrc.startsWith("http")) {
                link.setAttribute("crossorigin", "anonymous");
            }
            document.head.appendChild(link);

            // 실제 이미지도 메모리에 미리 로드
            const img = new Image();
            img.src = imageSrc;
            if (imageSrc.startsWith("http")) {
                img.crossOrigin = "anonymous";
            }
            // 특히 LCP 요소인 홈 화면 이미지는 우선순위 높게 로드
            if (imageSrc.includes("home_top")) {
                // fetchPriority는 표준 속성이 아니므로 setAttribute 사용
                img.setAttribute("fetchpriority", "high");
                img.decoding = "async";
                img.loading = "eager";
            }
        });

        // 초기 로딩 화면 제거
        const initialLoading = document.querySelector(".initial-loading");
        if (initialLoading) {
            initialLoading.classList.add("fade-out");
            setTimeout(() => {
                if (initialLoading.parentNode) {
                    try {
                        initialLoading.parentNode.removeChild(initialLoading);
                    } catch (e) {
                        console.log("초기 로딩 화면 제거 중 오류:", e);
                    }
                }
            }, 500);
        }

        // 스크립트 지연 로드를 위한 처리
        setTimeout(() => {
            const nonCriticalScripts = [
                "https://www.google-analytics.com/analytics.js",
            ];

            nonCriticalScripts.forEach((scriptSrc) => {
                const script = document.createElement("script");
                script.src = scriptSrc;
                script.async = true;
                document.body.appendChild(script);
            });
        }, 2000);
    }, []);

    return null;
};

// 로딩 중 표시할 폴백 컴포넌트
const Loading = () => (
    <LoadingContainer>
        <RotatingLogo
            src={`${process.env.PUBLIC_URL}/pavicon.ico`}
            alt="온비 로고"
            width={80}
            height={80}
            fetchPriority="high"
        />
        <LoadingText>온비가 로딩 중입니다...</LoadingText>
    </LoadingContainer>
);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

root.render(
    <React.StrictMode>
        <ImagePreloader />
        <React.Suspense fallback={<Loading />}>
            <App />
        </React.Suspense>
    </React.StrictMode>,
);

// 성능 측정 및 보고를 위한 웹 바이탈 측정
reportWebVitals(
    process.env.NODE_ENV === "development" ? console.log : undefined,
);
